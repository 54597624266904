import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Autocomplete,
  CircularProgress,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ExcelIcon from "../../../../assets/Plataforma/excelIcon.svg";
import PourposeIcon from "../../../../assets/Plataforma/pourposeIcon.svg";
import CircleCheckbox from "../../../../components/CircleCheckBox";
import Datatable from "../../../../components/Datatable";
import StatusCard from "../../../../components/StatusCard";
import {
  EnumStatusLead,
  EnumStatusLeadColorMap,
  EnumStatusLeadNumericMap,
} from "../../../../enums/EnumStatusLead.enum";
import { useAuth } from "../../../../hooks/useAuth";
import { ICaptadorListItem } from "../../../../models/Captador";
import { IBackofficeListItem } from "../../../../models/Colaborador";
import {
  ICreateLead,
  IFullLead,
  ILead,
  ILeadsRelatoriosFilters,
  IUpdateLead,
} from "../../../../models/Lead";
import { ILeadsStats } from "../../../../models/Stats";
import * as captadorServices from "../../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../../services/api/ColaboradorService";
import * as services from "../../../../services/api/LeadService";
import * as logServices from "../../../../services/api/LogService";
import * as anexoServices from "../../../../services/api/AnexoService";
import * as D from "../../../../styles/appComponents";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import HistoricoCard from "../../../../components/HistoricoCard";
import { ICreateLeadLog, ILog } from "../../../../models/Log";
import SkeletonDefaultPage from "../../../../components/SkeletonLoads/DefaultPage";

const ParceiroLeads: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [editar, setEditar] = useState(false);
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openNewLead, setOpenNewLead] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [stats, setStats] = useState<ILeadsStats>({
    leads: 0,
    aguardandoEnvioConta: 0,
    contaAnexada: 0,
    propostaGerada: 0,
    cancelado: 0,
  });
  const [leadsRows, setLeadsRows] = useState<ILead[]>([]);

  const [celular, setCelular] = useState<string | null>("");
  const [email, setEmail] = useState<string | null>("");
  const [valor, setValor] = useState<string | null>("");
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [nome, setNome] = useState<string | null>("");

  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);

  const [nomeCreateLead, setNomeCreateLead] = useState<string | null>("");
  const [celularCreateLead, setCelularCreateLead] = useState<string | null>("");

  const [loadingCreateLead, setLoadingCreateLead] = useState(false);

  //logs
  const [historicoLeads, setHistoricoLeads] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);

  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  const createNewLog = async () => {
    if (historicoAssunto == "" || historicoDescricao == "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto/descrição não podem ser vazios.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const historicoData: ICreateLeadLog = {
      gdLeadId: leadModal.id,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLeadLog(historicoData)
      .then(() => {
        getLeadModalData(leadModal.id);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  //filters
  const [selectedBackoffice, setSelectedBackoffice] = useState<
    string | undefined
  >();
  const [selectedCaptador, setSelectedCaptador] = useState<
    string | undefined
  >();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();

  const [leadModal, setLeadModal] = useState<IFullLead>({
    id: "",
    idReferencial: "",
    celular: "",
    email: "",
    nome: "",
    nomeBackoffice: "",
    nomeCaptador: "",
    status: EnumStatusLead.leads,
    valorMedioConta: "",
    conta: null,
  });

  const navigate = useNavigate();

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const getBackoffices = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getCaptadores = () => {
    captadorServices
      .GetCaptadores()
      .then((response) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const geraRelatorio = () => {
    var filters: ILeadsRelatoriosFilters = {
      status: selectedStatus,
      backofficeId: selectedBackoffice,
      captadorId: selectedCaptador,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };

    services
      .GeraRelatorio(filters)
      .then(async (response) => {
        const blob = response.data;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `leads_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os backoffices.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleChangeModal = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {};

  const handleOpen = async (id: string) => {
    await getLeadModalData(id);
    setOpen(true);
  };

  const getLogsByLeadId = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistoricoLeads(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLeadModalData = async (id: string) => {
    await services
      .GetLeadById(id)
      .then((response) => {
        setLeadModal(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    await getLogsByLeadId(id);
  };

  const handleClose = async () => {
    setCelular("");
    setNome("");
    setEmail("");
    setSelectedId(null);
    setValor("");
    setEditar(false);
    await setLeadModal({
      id: "",
      idReferencial: "",
      celular: "",
      email: "",
      nome: "",
      nomeBackoffice: "",
      nomeCaptador: "",
      status: EnumStatusLead.leads,
      valorMedioConta: "",
      conta: null,
    });
    setOpen(false);
  };

  const handleOpenNewLead = async () => {
    setOpenNewLead(true);
  };

  const handleCloseNewLead = async () => {
    setCelularCreateLead("");
    setNomeCreateLead("");
    await setLeadModal({
      id: "",
      idReferencial: "",
      celular: "",
      email: "",
      nome: "",
      nomeBackoffice: "",
      nomeCaptador: "",
      status: EnumStatusLead.leads,
      valorMedioConta: "",
      conta: null,
    });
    setOpenNewLead(false);
  };

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  const handleStatusOpen = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUpdateLead = async () => {
    await setLoadingSaveEdit(true);
    var uploadLeadData: IUpdateLead = {
      id: leadModal.id,
      celular: celular,
      email: email,
      nome: nome,
      status: selectedId,
      valor: valor,
    };

    await services
      .UpdateLead(uploadLeadData)
      .then(() => {
        getLeadModalData(leadModal.id);
        setEditar(false);

        setLoadingSaveEdit(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Editado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        setLoadingSaveEdit(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLeadsStats = () => {
    services
      .GetLeadsStats()
      .then((response: { data: React.SetStateAction<ILeadsStats> }) => {
        setStats(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getLeads = async () => {
    await getLeadsStats();

    services
      .GetLeads(page, pageSize)
      .then((response) => {
        setLeadsRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const createLead = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingCreateLead(true);
    var createLead: ICreateLead = {
      idCaptador: user?.idCaptador!,
      celular: celularCreateLead!,
      nome: nomeCreateLead!,
      conta: null,
      email: null,
      urlCaptador: null,
      valor: null,
    };

    services
      .CreateLead(createLead)
      .then((response) => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Criado com sucesso",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
        handleCloseNewLead();
        getLeads();
        getLeadsStats();
        setLoadingCreateLead(false);
      })
      .catch((e: AxiosError) => {
        setLoadingCreateLead(false);
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao criar a lead.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    getLeads();
    getBackoffices();
    getCaptadores();
  }, [page, pageSize, open]);

  useEffect(() => {
    setCelular(leadModal.celular);
    setNome(leadModal.nome);
    setEmail(leadModal.email);
    setSelectedId(Number(leadModal.status));
    setValor(leadModal.valorMedioConta);
  }, [leadModal]);

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "celular", headerName: "Celular", flex: 1 },
    {
      field: "nomeCaptador",
      headerName: "Parceiro",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "valorMedioConta",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>{moneyFormatter.format(Number(params.value))}</D.GridField>
      ),
    },
    {
      field: "createdAt",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          {new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={EnumStatusLeadColorMap[Number(params.value)]} />
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Gerar Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField
          $alignCenter
          onClick={() => navigate(`/Parceiro/Novo/Proposta/${params.id}`)}
        >
          <D.GridImage src={PourposeIcon} alt="pourposeIcon" />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              <C.StatusWrapper>
                <StatusCard color="blue" text="Leads" number={stats.leads} />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="yellow"
                  text="Aguardando envio de conta"
                  number={stats.aguardandoEnvioConta}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="green"
                  text="Conta Anexada"
                  number={stats.contaAnexada}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="purple"
                  text="Proposta Gerada"
                  number={stats.propostaGerada}
                />
              </C.StatusWrapper>
              <C.StatusWrapper>
                <StatusCard
                  color="red"
                  text="Cancelado"
                  number={stats.cancelado}
                />
              </C.StatusWrapper>
            </C.StatusArea>
          )}
          <D.DataArea $align="right">
            <D.ContainedButton
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleOpenNewLead}
            >
              Nova lead
            </D.ContainedButton>
            {!isMobile ? (
              <Datatable
                columns={columns}
                rows={leadsRows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
              />
            ) : (
              <>
                <C.CardDataAreaMobile>
                  {leadsRows.map((row) => (
                    <C.CardDataMobile key={row.id}>
                      <C.CardDataLineMobile>
                        <C.CardDataItemMobile>
                          <label>Id:</label>
                          <p>{row.id}</p>
                        </C.CardDataItemMobile>
                        <C.CardDataItemMobile></C.CardDataItemMobile>
                        <C.CardDataItemMobile $center={true}>
                          <label>Status:</label>
                          <D.Circle
                            color={EnumStatusLeadColorMap[Number(row.status)]}
                          />
                        </C.CardDataItemMobile>
                      </C.CardDataLineMobile>
                      <C.CardDataLineMobile>
                        <C.CardDataItemMobile>
                          <label>Nome:</label>
                          <p>{row.nome}</p>
                        </C.CardDataItemMobile>
                      </C.CardDataLineMobile>
                      <C.CardDataLineMobile>
                        <C.CardDataItemMobile>
                          <label>Parceiro:</label>
                          <p>{row.nomeCaptador}</p>
                        </C.CardDataItemMobile>
                      </C.CardDataLineMobile>
                      <C.CardDataLineMobile>
                        <C.CardDataItemMobile>
                          <label>Telefone:</label>
                          <p>{row.celular}</p>
                        </C.CardDataItemMobile>
                        <C.CardDataItemMobile>
                          <label>Data:</label>
                          <p>
                            {new Date(
                              String(row.createdAt)
                            ).toLocaleDateString()}
                          </p>
                        </C.CardDataItemMobile>
                        <C.CardDataItemMobile $center={true}>
                          <D.DefaultGridButton
                            onClick={() => handleOpen(row.id)}
                          >
                            Ver mais
                          </D.DefaultGridButton>
                        </C.CardDataItemMobile>
                      </C.CardDataLineMobile>
                    </C.CardDataMobile>
                  ))}
                </C.CardDataAreaMobile>
                <D.DefaultPagination
                  count={10}
                  size={"small"}
                  siblingCount={0}
                  showFirstButton
                  showLastButton
                  onChange={handleChangeModal}
                  color="primary"
                />
              </>
            )}
            <C.LineBreak />
            <C.ReportsArea>
              <C.SelectArea>
                <C.ReportsLabel>Status de leads</C.ReportsLabel>
                <C.ReportsSelect
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={""}
                  onChange={(e) => setSelectedStatus(e.target.value as string)}
                >
                  <MenuItem value="">
                    <C.DefaultColorText>Todos os status</C.DefaultColorText>
                  </MenuItem>
                  {Object.keys(EnumStatusLead).map((key, index) => (
                    <MenuItem
                      key={key}
                      value={
                        EnumStatusLeadNumericMap[
                          EnumStatusLead[key as keyof typeof EnumStatusLead]
                        ]
                      }
                    >
                      {EnumStatusLead[key as keyof typeof EnumStatusLead]}
                    </MenuItem>
                  ))}
                </C.ReportsSelect>
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione um captador</C.ReportsLabel>
                <Autocomplete
                  options={captadores}
                  fullWidth
                  noOptionsText="Nenhum captador encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os captadores"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    setSelectedCaptador(newValue?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                <Autocomplete
                  options={backoffices}
                  fullWidth
                  noOptionsText="Nenhum backoffice encontrado"
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Todos os backoffices"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Without label",
                      }}
                    />
                  )}
                  defaultValue={null}
                  onChange={(event, newValue) => {
                    setSelectedBackoffice(newValue?.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data início"
                  fullWidth
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                />
              </C.SelectArea>
              <C.SelectArea>
                <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                <D.DateTextfield
                  type="date"
                  label="Data fim"
                  fullWidth
                  value={dataFim}
                  onChange={(e) => setDataFim(e.target.value)}
                />
              </C.SelectArea>
            </C.ReportsArea>
            <C.ExportButton
              onClick={geraRelatorio}
              variant="contained"
              startIcon={
                <img
                  style={{ width: "20px", color: "white", fill: "white" }}
                  src={ExcelIcon}
                  alt="excelIcon"
                />
              }
            >
              Gerar relatório
            </C.ExportButton>
          </D.DataArea>
        </C.Container>
      )}
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={openNewLead}
        onClose={handleCloseNewLead}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard onSubmit={createLead}>
              <C.ModalHeader>
                <h2>Nova lead</h2>
                <C.CloseButton aria-label="close" onClick={handleCloseNewLead}>
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalInputsArea>
                <C.ModalInput
                  label="Nome"
                  value={nomeCreateLead}
                  onChange={(e) => setNomeCreateLead(e.target.value)}
                  required
                />
                <C.ModalInput
                  label="Telefone"
                  value={celularCreateLead}
                  onChange={(e) => setCelularCreateLead(e.target.value)}
                  required
                />
              </C.ModalInputsArea>
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <D.ContainedButton
                  variant="contained"
                  $color={"lightpink"}
                  onClick={handleCloseNewLead}
                >
                  Cancelar
                </D.ContainedButton>
                <D.ContainedButton variant="contained" type="submit">
                  {loadingCreateLead ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <>Salvar</>
                  )}
                </D.ContainedButton>
              </Stack>
            </C.ModalCard>
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
      <Modal
        sx={{ width: "100%", zIndex: 10 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  {leadModal.nome} - {leadModal.idReferencial}
                </h2>
                <C.CloseButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <h4>Status atual:</h4>
              <C.ModalStatusArea>
                <CircleCheckbox
                  id={EnumStatusLeadNumericMap[EnumStatusLead.leads]}
                  label="Leads"
                  color="blue"
                  selected={
                    selectedId ===
                    EnumStatusLeadNumericMap[EnumStatusLead.leads]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={
                    EnumStatusLeadNumericMap[
                      EnumStatusLead.aguardandoEnvioConta
                    ]
                  }
                  label="Aguardando envio de conta"
                  color="yellow"
                  selected={
                    selectedId ===
                    EnumStatusLeadNumericMap[
                      EnumStatusLead.aguardandoEnvioConta
                    ]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]}
                  label="Conta anexada"
                  color="green"
                  selected={
                    selectedId ===
                    EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]}
                  label="Proposta Gerada"
                  color="purple"
                  selected={
                    selectedId ===
                    EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]
                  }
                  onSelect={handleSelect}
                />
                <CircleCheckbox
                  id={EnumStatusLeadNumericMap[EnumStatusLead.cancelada]}
                  label="Cancelada"
                  color="red"
                  selected={
                    selectedId ===
                    EnumStatusLeadNumericMap[EnumStatusLead.cancelada]
                  }
                  onSelect={handleSelect}
                />
              </C.ModalStatusArea>
              <C.ModalInputsArea>
                <C.ModalInput
                  label="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Telefone"
                  value={celular}
                  onChange={(e) => setCelular(e.target.value)}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Email"
                  value={email ? email : ""}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{ readOnly: !editar }}
                />
                <C.ModalInput
                  label="Parceiro"
                  value={leadModal.nomeCaptador}
                  inputProps={{ readOnly: true }}
                />
                <C.ModalInput
                  label="Backoffice"
                  value={leadModal.nomeBackoffice}
                  inputProps={{ readOnly: true }}
                />
                <C.ModalInput
                  label="Valor"
                  value={valor ? valor : ""}
                  onChange={(e) => setValor(e.target.value)}
                  inputProps={{ readOnly: !editar }}
                  InputProps={{
                    startAdornment: <>R$</>,
                  }}
                />
              </C.ModalInputsArea>
              <C.ModalButtonsArea>
                <C.ModalProposalButton
                  variant="contained"
                  onClick={() =>
                    navigate(`/Parceiro/Novo/Proposta/${leadModal.id}`)
                  }
                >
                  Gerar proposta
                </C.ModalProposalButton>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={editar}
                  onClick={() => setEditar(true)}
                >
                  Editar
                </C.ModalSaveButton>
                <C.ModalSaveButton
                  variant="contained"
                  disabled={!editar}
                  onClick={() => handleUpdateLead()}
                >
                  {loadingSaveEdit ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <>Salvar</>
                  )}
                </C.ModalSaveButton>
              </C.ModalButtonsArea>
            </C.ModalCard>
            <HistoricoCard
              tableData={historicoLeads}
              getNewHistoricData={handleNewHistoricoChange}
              saveFunction={createNewLog}
              reloadTable={() => getLeadModalData(leadModal.id)}
            />
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    </>
  );
};

export default ParceiroLeads;
