import { ICaptador } from "../../models/Captador";
import { IParceiro } from "../../models/Parceiro";
import { IParceiroConfig } from "../../models/ParceiroConfig";
import http from "../common/http-common";

export const GetAll = () => {
  return http.get<IParceiro[]>("/Usuario/Parceiro");
};

export const GetById = (id: string) => {
  return http.get<ICaptador>(`/Captador/${id}`);
};

export const Create = (parceiroData: IParceiro) => {
  return http.post<IParceiro>("/Usuario/Parceiro", parceiroData);
};

export const GetParceiroConfigByUrl = (url: string) => {
  return http.get<IParceiroConfig>(`/CaptadorConfig/${url}`);
};

export const GetParceiroConfigByLeadId = (leadId: string) => {
  return http.get<IParceiroConfig>(`/CaptadorConfig/GetConfigByLead/${leadId}`);
};
