import { useParams } from "react-router-dom";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import InformacoesTitularContratar from "./components/InformacoesTitular";

const Contratar = () => {
  const { idProposta } = useParams();

  return (
    <C.Container>
      <InformacoesTitularContratar />
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <D.ContainedButton $color="yellow">Limpar campos</D.ContainedButton>
        <D.ContainedButton $color="lightpink">
          Gravar proposta
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default Contratar;
