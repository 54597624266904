import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import colaborador from "../../../../assets/Login/imgColaborador.svg";
import Login from "../../../../components/Login";
import * as C from "../../../../components/Login/style";
import { useAuth } from "../../../../hooks/useAuth";
import { ILoginParams } from "../../../../models/Usuario";
import { AxiosError } from "axios";
import * as services from "../../../../services/api/UsuarioService"

const topics: string[] = [
  "Você é a engrenagem vital desta empresa. Obrigado por ser parte fundamental de nosso sucesso.",
  "Sua dedicação e compromisso são essenciais para o crescimento e sucesso da empresa.",
  "Sua presença é mais do que apenas uma entrada no sistema. Você é a força motriz por trás de nossa empresa. Obrigado por tudo o que você faz!",
];

const LoginColaborador: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login } = useAuth();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var loginParams: ILoginParams = {
      user,
      password,
    };

    await services
      .LoginColaborador(loginParams)
      .then(async (response: any) => {
        await login(response.data);

        navigate("/Colaborador/Dashboard");

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Bem vindo!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao fazer o login.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      <Login
        title="colaborador"
        topics={topics}
        imageSrc={colaborador}
        formTitle="Que bom ter você com a gente!"
        formSubtitle="Faça seu login abaixo"
      >
        <C.LoginForm onSubmit={handleLogin}>
          <C.LoginInput
            label="Login"
            variant="outlined"
            required
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <C.LoginInput
            label="Senha"
            variant="outlined"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <C.LinksArea>
            <div></div>
            <C.Links to="/Login/Colaborador/RecuperarSenha">
              Esqueceu sua senha?
            </C.Links>
          </C.LinksArea>
          <C.LoginButton type="submit" variant="contained">
            Acessar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default LoginColaborador;
