import FileUploadIcon from "@mui/icons-material/FileUpload";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Button,
  CircularProgress,
  ThemeProvider as MuiProvider,
  createTheme,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider as StyledProvider } from "styled-components";
import Swal from "sweetalert2";
import SkeletonLoadLeads from "../../../../components/SkeletonLoads/Leads";
import { IParceiroConfig } from "../../../../models/ParceiroConfig";
import * as leadServices from "../../../../services/api/LeadService";
import * as parceiroServices from "../../../../services/api/ParceiroService";
import * as C from "../style";

const AnexarArquivo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [anexouConta, setAnexouConta] = useState(false);
  const [insideLoading, setInsideLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [conta, setConta] = useState<File | null>(null);
  const [cellPhone, setCellphone] = useState({
    ddd: "31",
    telefone: "99999-9999",
  });
  const [theme, setTheme] = useState<IParceiroConfig>({
    id: "",
    celular: "31123412343",
    corPrimaria: "#fc0303",
    corSecundaria: "#1900ff",
    corTextoBase: "#ffffff",
    corTextoDestaque: "#00ff44",
    textoBoasVindas:
      "*A WATTWISE CONVIDA* VOCÊ A ECONOMIZAR ATÉ *25% EM SUA TARIFA DE ENERGIA.*",
    textoAgradecimento:
      "VOCÊ ESTÁ A UM PASSO DE RECEBER ATÉ 25% DE DESCONTO NA SUA CONTA DE LUZ, EM BREVE TE RETORNAREMOS. *FIQUE ATENTO AO SEU EMAIL.*",
    textoAnexarConta:
      "VOCÊ ESTÁ A UM PASSO DE ECONOMIZAR EM ATÉ 25% NA SUA CONTA DE ENERGIA.",
    textoAgradecimentoAnexarConta:
      "OBRIGADO POR ANEXAR SUA CONTA! ENTRAREMOS EM CONTATO EM BREVE COM UMA PROPOSTA.",
    temPixelFacebook: false,
    pixelFacebook: "",
    logo: "",
    background: "",
  });
  const [muiTheme, setMuiTheme] = useState(
    createTheme({
      palette: {
        primary: {
          main: theme.corPrimaria,
        },
        secondary: {
          main: theme.corSecundaria,
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: "700",
              color: theme.corPrimaria,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color: "#333333",
              backgroundColor: "#ffffff",
            },
          },
        },
      },
    })
  );

  const handleContaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setConta(event.target.files[0]);
    }
  };

  const markedTexts = (text: string) => {
    return text.split("*").map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return <C.MarkedText key={index}>{part}</C.MarkedText>;
      }
    });
  };

  useEffect(() => {
    parceiroServices
      .GetParceiroConfigByLeadId(id!)
      .then(
        async (response: { data: React.SetStateAction<IParceiroConfig> }) => {
          await setTheme(response.data);

          setLoading(false);
        }
      )
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        navigate("/");

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });

    leadServices
      .VerifyUploadedBill(id!)
      .then(async (response) => {
        await setAnexouConta(response.data.anexado);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }, []);

  useEffect(() => {
    setMuiTheme(
      createTheme({
        palette: {
          primary: {
            main: theme.corPrimaria,
          },
          secondary: {
            main: theme.corSecundaria,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontWeight: "700",
                color: theme.corPrimaria,
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                color: "#333333",
                backgroundColor: "#ffffff",
              },
            },
          },
        },
      })
    );

    const dddPart = theme.celular.slice(0, 2);
    const restOfNumber = theme.celular.slice(2);

    const formattedRest =
      restOfNumber.slice(0, 5) + "-" + restOfNumber.slice(5);

    setCellphone({
      ddd: dddPart,
      telefone: formattedRest,
    });
  }, [theme]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    await setInsideLoading(true);

    if (conta == null) {
      setInsideLoading(false);
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Conta não pode ser vazia.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    await leadServices
      .AnexarConta(id!, conta)
      .then(() => {
        setInsideLoading(false);
        setAnexouConta(true);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Enviado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        setInsideLoading(false);

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  }
  return loading ? (
    <SkeletonLoadLeads></SkeletonLoadLeads>
  ) : (
    <StyledProvider theme={theme}>
      <MuiProvider theme={muiTheme}>
        <C.Container>
          <C.Navbar>
            <img src={theme.logo} alt="Logo" />
            <C.Wpp
              href={`https://wa.me/55${theme.celular}?text=Ol%C3%A1,%20Quero%20economizar%20na%20minha%20conta%20de%20energia.`}
              target="_blank"
            >
              <WhatsAppIcon />
              <C.WppNumber>
                <C.DDD>{cellPhone.ddd}</C.DDD> {cellPhone.telefone}
              </C.WppNumber>
            </C.Wpp>
          </C.Navbar>
          <C.Content>
            <C.Left>
              <C.Text>{markedTexts(theme.textoAnexarConta)}</C.Text>
            </C.Left>
            <C.Right>
              <C.FormArea onSubmit={handleSubmit}>
                {!anexouConta ? (
                  <>
                    <C.FormTitle>INSIRA SUA CONTA DE LUZ</C.FormTitle>
                    <C.BillsArea direction="column" spacing={2}>
                      <Button
                        color="secondary"
                        component="label"
                        sx={{ padding: "10px" }}
                        role={undefined}
                        variant="contained"
                        fullWidth
                        startIcon={<FileUploadIcon />}
                      >
                        Anexar conta
                        <C.VisuallyHiddenInput
                          type="file"
                          onChange={handleContaChange}
                        />
                      </Button>
                      <h4>{conta ? conta.name : "Nenhum arquivo anexado."}</h4>
                    </C.BillsArea>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      type="submit"
                    >
                      {insideLoading ? (
                        <CircularProgress color="primary" size={22} />
                      ) : (
                        "Solicitar Proposta"
                      )}
                    </Button>
                  </>
                ) : (
                  <>
                    <C.FormTitle>
                      {markedTexts(theme.textoAgradecimentoAnexarConta)}
                    </C.FormTitle>
                  </>
                )}
              </C.FormArea>
            </C.Right>
          </C.Content>
        </C.Container>
      </MuiProvider>
    </StyledProvider>
  );
};

export default AnexarArquivo;
