import {
  ICreateLeadLog,
  IFullLog,
  ILog,
  IUpdateLeadLog,
} from "../../models/Log";
import http from "../common/http-common";

export const GetLogsByItemId = (id: string) => {
  return http.get<ILog[]>(`/LogGeral/GetByItem/${id}`);
};

export const CreateLeadLog = (data: ICreateLeadLog) => {
  const formData = new FormData();

  formData.append("Assunto", data.assunto);
  formData.append("Descricao", data.descricao);
  formData.append("GDLeadId", data.gdLeadId);

  if (data.anexo != null) {
    formData.append("Anexo", data.anexo);
  }

  return http.post<ILog>(`/LogGeral`, formData);
};

export const DeleteLog = (id: string) => {
  return http.delete(`/LogGeral/${id}`);
};

export const GetLogById = (id: string) => {
  return http.get<IFullLog>(`/LogGeral/${id}`);
};

export const UpdateLog = (data: IUpdateLeadLog) => {
  const formData = new FormData();

  formData.append("Assunto", data.assunto);
  formData.append("Descricao", data.descricao);
  formData.append("Id", data.id);

  if (data.anexo != null) {
    formData.append("Anexo", data.anexo);
  }

  return http.put<ILog>(`/LogGeral`, formData);
};
