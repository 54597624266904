import { ColorType } from "../types/color";

export enum EnumStatusGDProposta {
  naoTeveInteresse = "Não teve interesse",
  emAnalise = "Em análise",
  aprovada = "Aprovada",
  contratoGerado = "Contrato Gerado",
  reprovada = "Reprovada",
  cancelada = "Cancelada",
}

export const StatusPropostaNumberMap: {
  [key: number]: EnumStatusGDProposta;
} = {
  0: EnumStatusGDProposta.naoTeveInteresse,
  1: EnumStatusGDProposta.emAnalise,
  2: EnumStatusGDProposta.contratoGerado,
  3: EnumStatusGDProposta.reprovada,
  4: EnumStatusGDProposta.cancelada,
};

export const EnumStatusGDPropostaNumericMap = {
  [EnumStatusGDProposta.naoTeveInteresse]: 0,
  [EnumStatusGDProposta.emAnalise]: 1,
  [EnumStatusGDProposta.contratoGerado]: 2,
  [EnumStatusGDProposta.reprovada]: 3,
  [EnumStatusGDProposta.cancelada]: 4,
};

export const StatusPropostaColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "purple",
};
