import { useNavigate, useParams } from "react-router-dom";
import DadosPessoaisGerarProposta from "./DadosPessoais";
import * as C from "../style";
import ConexaoContaGerarProposta from "./ConexaoConta";
import ContaCooperativaGerarProposta from "./ContaCooperativa";
import ContaDistribuidoraGerarProposta from "./ContaDistribuidora";
import ResumoFaturaGerarProposta from "./ResumoFatura";
import StatusPropostaGerarProposta from "./StatusProposta";
import EnviarPropostaGerarProposta from "./EnviarProposta";
import * as propostaService from "../../../../../services/api/PropostaService";
import * as D from "../../../../../styles/appComponents";
import { useEffect, useState } from "react";
import {
  DefaultUpdateGDProposta,
  IConexaoContaGerarProposta,
  IContaCooperativaProposta,
  IContaDistribuidoraProposta,
  ICreateGDProposta,
  IDadosPessoaisGerarProposta,
  IGetCalculoResponse,
  IResumoFaturaProposta,
} from "../../../../../models/GDProposta";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { EnumTipoConexaoValueMap } from "../../../../../enums/EnumTipoConexao.enum";

const GerarProposta = () => {
  const { idLead } = useParams();
  const navigate = useNavigate();

  const [dadosPessoaisData, setDadosPessoaisData] =
    useState<IDadosPessoaisGerarProposta>({
      nome: null,
      cpf: null,
      celular: null,
      email: null,
      cooperativaId: null,
    });

  const [conexaoContaData, setConexaoContaData] =
    useState<IConexaoContaGerarProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      operadora: "",
      tarifaDistribuidora: "",
      valorConta: "",
    });

  const [contaCooperativaData, setContaCooperativaData] =
    useState<IContaCooperativaProposta>({
      energiaInjetadakWh: "",
      energiaInjetadaReais: "",
      valorCooperativa: "",
      valorDesconto: "",
    });

  const [contaDistribuidoraData, setContaDistribuidoraData] =
    useState<IContaDistribuidoraProposta>({
      impostoTotalDistribuidora: "",
      taxaDisponibilidadekWh: "",
      taxaDisponibilidadeReais: "",
      totalContaDistribuidora: "",
      tributosPisCofins: "",
    });

  const [resumoFaturaData, setResumoFaturaData] =
    useState<IResumoFaturaProposta>({
      contasEconomizadasAnualmente: "",
      economiaAnual: "",
      economiaMensalBruta: "",
      economiaMensalLiquida: "",
      tarifaCooperativa: "",
      valorTotal: "",
    });

  const getCalculoResponse = (data: IGetCalculoResponse) => {
    setContaCooperativaData({
      energiaInjetadakWh: data.kwhInjetado.toString(),
      energiaInjetadaReais: data.energiaInjetada.toString(),
      valorCooperativa: data.valorContaCooperativa.toString(),
      valorDesconto: "25",
    });
    setContaDistribuidoraData({
      impostoTotalDistribuidora: data.valorImpostosDistribuidora.toString(),
      taxaDisponibilidadekWh:
        EnumTipoConexaoValueMap[
          Number(
            conexaoContaData.conexao
          ) as keyof typeof EnumTipoConexaoValueMap
        ],
      taxaDisponibilidadeReais: data.valorTaxaDisponibilidade.toString(),
      totalContaDistribuidora: data.valorContaDistribuidora.toString(),
      tributosPisCofins: "3.69",
    });
    setResumoFaturaData({
      contasEconomizadasAnualmente: data.contasEconomizadas.toString(),
      economiaAnual: data.economiaAnual.toString(),
      economiaMensalBruta: data.economiaMensalBruta.toString(),
      economiaMensalLiquida: data.economiaMensalLiquida.toString(),
      tarifaCooperativa: data.tarifaUsina.toString(),
      valorTotal: data.valorTotalFatura.toString(),
    });
  };

  const [createProposta, setCreateProposta] = useState<ICreateGDProposta>();

  const receiveDataDadosPessoais = (data: IDadosPessoaisGerarProposta) => {
    setDadosPessoaisData(data);
  };

  const receiveDataConexaoConta = (data: IConexaoContaGerarProposta) => {
    setConexaoContaData(data);
  };

  const createPropostaAsync = async () => {
    const data: ICreateGDProposta = {
      ...dadosPessoaisData,
      ...conexaoContaData,
      ...contaCooperativaData,
      ...contaDistribuidoraData,
      ...resumoFaturaData,
      leadId: idLead ?? null,
      status: 1,
    };

    var adjustedData = await convertEmptyStringsToNullAsync(data);

    setCreateProposta(adjustedData);
  };

  useEffect(() => {
    createPropostaAsync();
  }, [
    dadosPessoaisData,
    conexaoContaData,
    contaCooperativaData,
    contaDistribuidoraData,
  ]);

  const geraProposta = () => {
    createProposta
      ? propostaService
          .CreateGDProposta(createProposta)
          .then(async () => {
            await navigate("/Parceiro/Propostas");
            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "success",
              title: "Criado com sucesso!",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          })
          .catch((e: AxiosError) => {
            var errorMessage: string = e.response
              ? String(e.response?.data)
              : "Houve um erro ao pegar os dados.";

            Swal.fire({
              position: "top-end",
              toast: true,
              icon: "warning",
              title: errorMessage,
              showConfirmButton: false,
              showCloseButton: true,
              timer: 3000,
            });
          })
      : Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: "Create proposta não pode ser nulo.",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
  };

  const convertEmptyStringsToNullAsync = async (
    obj: ICreateGDProposta
  ): Promise<ICreateGDProposta> => {
    const updatedObj = { ...obj };

    await Promise.all(
      (Object.keys(updatedObj) as (keyof ICreateGDProposta)[]).map(
        async (key) => {
          const value = updatedObj[key];
          if (typeof value === "string" && value === "") {
            updatedObj[key] = null;
          }
        }
      )
    );

    return updatedObj;
  };

  return (
    <C.Container>
      <DadosPessoaisGerarProposta
        leadId={idLead!}
        onSendData={receiveDataDadosPessoais}
      />
      <ConexaoContaGerarProposta
        onSendData={receiveDataConexaoConta}
        sendCalculoProposta={getCalculoResponse}
      />
      <ContaCooperativaGerarProposta data={contaCooperativaData} />
      <ContaDistribuidoraGerarProposta
        data={contaDistribuidoraData}
        iluPub={conexaoContaData.iluminacaoPublica!.toString()}
      />
      <ResumoFaturaGerarProposta data={resumoFaturaData} />
      <StatusPropostaGerarProposta />
      {/* <EnviarPropostaGerarProposta /> */}
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        {/* <D.ContainedButton $color="yellow">Limpar campos</D.ContainedButton> */}
        <D.ContainedButton $color="lightpink" onClick={geraProposta}>
          Gravar proposta
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default GerarProposta;
