import {
  ICreateLead,
  IFullLead,
  ILead,
  ILeadsRelatoriosFilters,
  IUpdateLead,
  IVerifyUploadedBill,
} from "../../models/Lead";
import { IPaginatedList } from "../../models/PaginatedList";
import { ILeadsStats } from "../../models/Stats";
import http from "../common/http-common";

export const GetLeadsStats = () => {
  return http.get<ILeadsStats>("/GDLead/StatsDashboard");
};

export const GetLeads = (pageNumber: number, pageSize: number) => {
  return http.get<IPaginatedList<ILead>>(
    `/GDLead?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const GetLeadById = (id: string) => {
  return http.get<IFullLead>(`/GDLead/${id}`);
};

export const CreateLead = (updateLeadData: ICreateLead) => {
  const formData = new FormData();

  formData.append("Nome", updateLeadData.nome.toString());
  formData.append("Celular", updateLeadData.celular);

  if (updateLeadData.email != null) {
    formData.append("Email", updateLeadData.email);
  }
  if (updateLeadData.idCaptador != null) {
    formData.append("IdCaptador", updateLeadData.idCaptador);
  }
  if (updateLeadData.urlCaptador != null) {
    formData.append("UrlCaptador", updateLeadData.urlCaptador);
  }
  if (updateLeadData.valor != null) {
    formData.append("ValorMedioConta", updateLeadData.valor);
  }
  if (updateLeadData.conta != null) {
    formData.append("Conta", updateLeadData.conta);
  }

  return http.post(`/GDLead`, formData);
};

export const UpdateLead = (updateLeadData: IUpdateLead) => {
  const formData = new FormData();

  formData.append("IdGDLead", updateLeadData.id.toString());
  if (updateLeadData.nome != null) {
    formData.append("Nome", updateLeadData.nome.toString());
  }
  if (updateLeadData.celular != null) {
    formData.append("Celular", updateLeadData.celular);
  }
  if (updateLeadData.email != null) {
    formData.append("Email", updateLeadData.email);
  }
  if (updateLeadData.valor != null) {
    formData.append("ValorMedioConta", updateLeadData.valor);
  }
  if (updateLeadData.status != null) {
    formData.append("Status", updateLeadData.status.toString());
  }

  return http.post(`/GDLead/Update`, formData);
};

export const AnexarConta = (idLead: string, conta: File) => {
  const formData = new FormData();

  formData.append("IdGDLead", idLead);
  formData.append("Conta", conta);

  return http.post(`/GDLead/AnexarConta`, formData);
};

export const VerifyUploadedBill = (id: string) => {
  return http.get<IVerifyUploadedBill>(`/GDLead/VerifyUploadedBill/${id}`);
};

export const GeraRelatorio = (filters: ILeadsRelatoriosFilters) => {
  const queryParams: string[] = [];

  if (filters.status !== undefined) {
    queryParams.push(`EnumStatusLead=${filters.status}`);
  }

  if (filters.captadorId) {
    queryParams.push(`CaptadorId=${filters.captadorId}`);
  }

  if (filters.backofficeId) {
    queryParams.push(`BackofficeId=${filters.backofficeId}`);
  }

  if (filters.dataInicio) {
    queryParams.push(`DataInicio=${filters.dataInicio}`);
  }

  if (filters.dataFim) {
    queryParams.push(`DataFim=${filters.dataFim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/GDLead/BuscaRelatorioLeads${queryString}`, {
    responseType: "blob", // Configura o Axios para tratar a resposta como um Blob
  });
};
