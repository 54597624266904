import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "./style";
import * as D from "../../../../../styles/appComponents";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CadastroCliente = () => {
  const [extraContacts, setExtraContacts] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>
      <RegisterCard title="Contrato Cliente">
        <C.TitleArea>
          <h2>ID - 9591</h2>
          <C.StatusArea>
            <label>Status:</label>
            <Stack direction={"row"} spacing={2}>
              <CircleCheckbox
                label="Ativo"
                color="yellow"
                id={2}
                selected={true}
                onSelect={() => {}}
              />
              <CircleCheckbox
                label="Inativo"
                color="yellow"
                id={3}
                selected={false}
                onSelect={() => {}}
              />
            </Stack>
          </C.StatusArea>
        </C.TitleArea>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Nome" required fullWidth />
          <TextField label="CPF" required fullWidth />
          <TextField label="Captador" required fullWidth />
          <TextField label="Celular captador" required fullWidth />
        </C.FWStack>
        <C.FWStack direction={"row"} spacing={2}>
          <TextField label="Celular" required fullWidth />
          <TextField label="E-mail" required fullWidth />
          <TextField label="Backoffice" required fullWidth />
          <C.FWDiv>
            <D.ContainedButton
              sx={{ width: "100%" }}
              startIcon={<AddIcon />}
              onClick={() => setExtraContacts(extraContacts + 1)}
              $color="lightpink"
            >
              Adicionar outra forma de contato
            </D.ContainedButton>
          </C.FWDiv>
        </C.FWStack>
        {Array.from({ length: extraContacts }).map((_, index) => (
          <C.FWStack direction={"row"} spacing={2} key={index}>
            <TextField label="Celular" required fullWidth />
            <TextField label="E-mail" required fullWidth />
            <TextField label="Backoffice" required fullWidth />
            <C.FWDiv>
              <D.ContainedButton>Salvar</D.ContainedButton>
            </C.FWDiv>
          </C.FWStack>
        ))}
        <C.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Login"
            type={showLogin ? "text" : "password"}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowLogin}
                    onMouseDown={handleMouseDownLogin}
                    edge="end"
                  >
                    {showLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Senha"
            type={showPassword ? "text" : "password"}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <C.FWDiv>
            <D.ContainedButton $color="lightpink">Editar</D.ContainedButton>
            <D.ContainedButton $color="yellow">Resetar senha</D.ContainedButton>
            <D.ContainedButton>Salvar</D.ContainedButton>
          </C.FWDiv>
        </C.FWStack>
      </RegisterCard>
    </>
  );
};

export default CadastroCliente;
