import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";

const StatusPropostaGerarProposta = () => {
  return (
    <RegisterCard title="Status da proposta">
      Status da proposta
      <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
        <D.Circle color="blue"></D.Circle> <span>Em análise</span>
      </D.FWStack>
    </RegisterCard>
  );
};

export default StatusPropostaGerarProposta;
