import { EnumStatusGDProposta } from "../enums/EnumStatusGDProposta.enum";
import { EnumTipoClasse } from "../enums/EnumTipoClasse.enum";
import { EnumTipoConexao } from "../enums/EnumTipoConexao.enum";
import { EnumTipoOperadora } from "../enums/EnumTipoOperadora.enum";

export interface IStatusDashboardProposta {
  status: EnumStatusGDProposta;
  quantidade: number;
  totalReais: number;
  totalkWh: number;
}

export interface IGDPropostaPaged {
  id: string;
  idReferencial: string;
  createdAt: Date;
  updatedAt: Date;
  cliente: string;
  instalacao: string;
  parceiro: string;
  temProposta: boolean;
  status: EnumStatusGDProposta;
}

export interface IGDProposta {
  id: string;
  idReferencial: string;
  createdAt: Date;
  updatedAt: Date;
  cliente: string;
  celular: string;
  status: EnumStatusGDProposta;
  nomeCaptador: string;
  celularCaptador: string;
  email: string;
  nomeBackoffice: string;
}

export interface ICreateGDProposta {
  leadId: string | null;
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  operadora: string | null;
  conexao: string | null;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorDesconto: string | null;
  valorCooperativa: string | null;
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  tributosPisCofins: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
  status: number | null;
}

export interface IUpdateGDProposta {
  id: string | null;
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  operadora: number | null;
  conexao: number | null;
  classe: number | null;
  valorConta: number | null;
  consumoMensalkWh: number | null;
  tarifaDistribuidora: number | null;
  iluminacaoPublica: number | null;
  numeroInstalacao: string | null;
  energiaInjetadakWh: number | null;
  energiaInjetadaReais: number | null;
  valorDesconto: number | null;
  valorCooperativa: number | null;
  taxaDisponibilidadekWh: number | null;
  taxaDisponibilidadeReais: number | null;
  tributosPisCofins: number | null;
  impostoTotalDistribuidora: number | null;
  totalContaDistribuidora: number | null;
  valorTotal: number | null;
  tarifaCooperativa: number | null;
  economiaMensalBruta: number | null;
  economiaMensalLiquida: number | null;
  economiaAnual: number | null;
  contasEconomizadasAnualmente: number | null;
  status: number | null;
}

export const DefaultUpdateGDProposta: IUpdateGDProposta = {
  id: null,
  nome: null,
  cpf: null,
  celular: null,
  email: null,
  operadora: null,
  conexao: null,
  classe: null,
  valorConta: null,
  consumoMensalkWh: null,
  tarifaDistribuidora: null,
  iluminacaoPublica: null,
  numeroInstalacao: null,
  energiaInjetadakWh: null,
  energiaInjetadaReais: null,
  valorDesconto: null,
  valorCooperativa: null,
  taxaDisponibilidadekWh: null,
  taxaDisponibilidadeReais: null,
  tributosPisCofins: null,
  impostoTotalDistribuidora: null,
  totalContaDistribuidora: null,
  valorTotal: null,
  tarifaCooperativa: null,
  economiaMensalBruta: null,
  economiaMensalLiquida: null,
  economiaAnual: null,
  contasEconomizadasAnualmente: null,
  status: null,
};

//Gera proposta data

export interface IDadosPessoaisGerarProposta {
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  cooperativaId: string | null;
}

export interface IConexaoContaGerarProposta {
  operadora: string | null;
  conexao: string;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
}

export interface IContaCooperativaProposta {
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorDesconto: string | null;
  valorCooperativa: string | null;
}

export interface IContaDistribuidoraProposta {
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  tributosPisCofins: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
}

export interface IResumoFaturaProposta {
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
}

export interface IGetCalculoParams {
  tipoConexao: string;
  valorConta: string;
  iluminacaoPublica: string;
  consumoMensalkWh: string;
  tarifaDistribuidora: string;
  pisConfins: string;
  percentualDesconto: string;
}

export interface IGetCalculoResponse {
  kwhInjetado: number;
  energiaInjetada: number;
  tarifaUsina: number;
  valorContaCooperativa: number;
  valorContaDistribuidora: number;
  valorTaxaDisponibilidade: number;
  iluminacaoPublica: number;
  valorImpostosDistribuidora: number;
  totalContaDistribuidora: number;
  valorTotalFatura: number;
  economiaMensalLiquida: number;
  economiaMensalBruta: number;
  economiaAnual: number;
  contasEconomizadas: number;
}
