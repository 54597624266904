import { MenuItem, TextField } from "@mui/material";
import RegisterCard from "../../../../../../../components/RegisterCard";
import * as D from "../../../../../../../styles/appComponents";
import { EnumEstadoCivil } from "../../../../../../../enums/EnumEstadoCivil.enum";

const InformacoesTitularContratar: React.FC = () => {
  return (
    <RegisterCard title="Informações do titular">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField label="Nome" required fullWidth />
        <TextField label="CPF" required fullWidth />
        <TextField label="Estado Civil" required fullWidth select>
          {Object.values(EnumEstadoCivil).map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField label="Nacionalidade" required fullWidth />
        <TextField label="Profissão" required fullWidth />
      </D.FWStack>
    </RegisterCard>
  );
};

export default InformacoesTitularContratar;
