import { TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as C from "../../style";
import QRCode from "react-qr-code";
import { EnumCupomBoasVindas } from "../../../../../../enums/EnumCupomBoasVindas.enum";
import { ICaptador } from "../../../../../../models/Captador";

interface IDadosAcessoParceiroProps {
  captador: ICaptador;
}

const DadosAcessoParceiro: React.FC<IDadosAcessoParceiroProps> = ({
  captador,
}) => {
  return (
    <RegisterCard title="Dados de acesso">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.user}
          label="Login"
          sx={{ width: "80%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={"não implementado"}
          label="Senha"
          type="password"
          sx={{ width: "80%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={
            Object.values(EnumCupomBoasVindas)[Number(captador.cupomBoasVindas)]
          }
          label="Cupom de boas vindas destinado para?"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.emissorNotaFiscal ? "Sim" : "Não"}
          label="Emissor de nota fiscal?"
          sx={{ width: "70%" }}
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.linkCaptador}
          label="Link do parceiro"
          fullWidth
          inputProps={{ readOnly: true }}
          InputProps={{
            startAdornment: (
              <C.StartTextTextfield>
                {`${process.env.REACT_APP_APP_URL?.replace(
                  "https://",
                  ""
                ).replace("http://", "")}Convida/`}
              </C.StartTextTextfield>
            ),
          }}
        />
        <C.FWDiv>
          <QRCode
            value={`${process.env.REACT_APP_APP_URL}Convida/${captador.linkCaptador}`}
          />
        </C.FWDiv>
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosAcessoParceiro;
