import { InputAdornment, MenuItem, TextField } from "@mui/material";
import RegisterCard from "../../../../../../components/RegisterCard";
import * as D from "../../../../../../styles/appComponents";
import {
  EnumTipoOperadora,
  EnumTipoOperadoraNumericMap,
} from "../../../../../../enums/EnumTipoOperadora.enum";
import {
  EnumTipoConexao,
  EnumTipoConexaoNumericMap,
} from "../../../../../../enums/EnumTipoConexao.enum";
import {
  EnumTipoClasse,
  EnumTipoClasseNumericMap,
} from "../../../../../../enums/EnumTipoClasse.enum";
import { useEffect, useState } from "react";
import {
  IConexaoContaGerarProposta,
  IGetCalculoParams,
  IGetCalculoResponse,
} from "../../../../../../models/GDProposta";
import * as service from "../../../../../../services/api/PropostaService";
import { AxiosError } from "axios";
import Swal from "sweetalert2";

interface IConexaoContaGerarPropostaProps {
  onSendData: (data: IConexaoContaGerarProposta) => void;
  sendCalculoProposta: (data: IGetCalculoResponse) => void;
}

const ConexaoContaGerarProposta: React.FC<IConexaoContaGerarPropostaProps> = ({
  onSendData,
  sendCalculoProposta,
}) => {
  const [operadora, setOperadora] = useState<string>("");
  const [conexao, setConexao] = useState<string>("");
  const [classe, setClasse] = useState<string>("");
  const [valorConta, setValorConta] = useState<string>("");
  const [consumoMensalkWh, setConsumoMensalkWh] = useState<string>("");
  const [tarifaDistribuidora, setTarifaDistribuidora] = useState<string>("");
  const [iluminacaoPublica, setIluminacaoPublica] = useState<string>("");
  const [numeroInstalacao, setNumeroInstalacao] = useState<string>("");

  useEffect(() => {
    const data: IConexaoContaGerarProposta = {
      operadora: operadora,
      conexao: conexao,
      classe: classe,
      valorConta: valorConta.replaceAll(",", "."),
      consumoMensalkWh,
      tarifaDistribuidora: tarifaDistribuidora.replaceAll(",", "."),
      iluminacaoPublica: iluminacaoPublica.replaceAll(",", "."),
      numeroInstalacao,
    };

    console.log(data);

    onSendData(data);
  }, [
    operadora,
    conexao,
    classe,
    valorConta,
    consumoMensalkWh,
    tarifaDistribuidora,
    iluminacaoPublica,
    numeroInstalacao,
  ]);

  const calcularProposta = () => {
    var data: IGetCalculoParams = {
      iluminacaoPublica: iluminacaoPublica.replaceAll(",", "."),
      consumoMensalkWh: consumoMensalkWh,
      percentualDesconto: "25",
      pisConfins: "3.69",
      tarifaDistribuidora: tarifaDistribuidora.replaceAll(",", "."),
      tipoConexao: conexao,
      valorConta: valorConta.replaceAll(",", "."),
    };

    service
      .GetCalculoProposta(data)
      .then((response) => {
        sendCalculoProposta(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <RegisterCard title="Conexao/Conta">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          select
          label="Operadora"
          fullWidth
          value={operadora}
          onChange={(e) => setOperadora(e.target.value)}
        >
          {Object.values(EnumTipoOperadora).map((value) => (
            <MenuItem key={value} value={EnumTipoOperadoraNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Conexão"
          fullWidth
          value={conexao}
          onChange={(e) => setConexao(e.target.value)}
        >
          {Object.values(EnumTipoConexao).map((value) => (
            <MenuItem key={value} value={EnumTipoConexaoNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Classe"
          fullWidth
          value={classe}
          onChange={(e) => setClasse(e.target.value)}
        >
          {Object.values(EnumTipoClasse).map((value) => (
            <MenuItem key={value} value={EnumTipoClasseNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Valor da conta"
          type="number"
          required
          fullWidth
          value={valorConta}
          onChange={(e) => setValorConta(e.target.value)}
          InputProps={{
            startAdornment: valorConta ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Consumo mensal"
          type="number"
          required
          fullWidth
          value={consumoMensalkWh}
          onChange={(e) => setConsumoMensalkWh(e.target.value)}
          InputProps={{
            endAdornment: consumoMensalkWh ? <>kWh</> : <></>,
          }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Tarifa distribuidora"
          type="number"
          required
          fullWidth
          value={tarifaDistribuidora}
          onChange={(e) => setTarifaDistribuidora(e.target.value)}
          InputProps={{
            startAdornment: tarifaDistribuidora ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Iluminação pública"
          type="number"
          required
          fullWidth
          value={iluminacaoPublica}
          onChange={(e) => setIluminacaoPublica(e.target.value)}
          InputProps={{
            startAdornment: iluminacaoPublica ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <TextField
          label="Número de instalação"
          type="number"
          required
          fullWidth
          value={numeroInstalacao}
          onChange={(e) => setNumeroInstalacao(e.target.value)}
        />
        <D.ContainedButton
          $color="lightpink"
          fullWidth
          onClick={calcularProposta}
        >
          Calcular proposta
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default ConexaoContaGerarProposta;
