import { IPaginatedList } from "../../models/PaginatedList";
import {
  ICreateGDProposta,
  IGDProposta,
  IGDPropostaPaged,
  IGetCalculoParams,
  IGetCalculoResponse,
  IStatusDashboardProposta,
  IUpdateGDProposta,
} from "../../models/GDProposta";
import http from "../common/http-common";
import { AxiosResponse } from "axios";

export const GetStats = () => {
  return http.get<IStatusDashboardProposta[]>(`/GDProposta/StatsDashboard`);
};

export const GetPropostas = (pageNumber: number, pageSize: number) => {
  return http.get<IPaginatedList<IGDPropostaPaged>>(
    `/GDProposta?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const GetPropostaById = (id: string) => {
  return http.get<IGDProposta>(`/GDProposta/${id}`);
};

export const UpdateGDProposta = (updateGDProposta: IUpdateGDProposta) => {
  return http.put(`/GDProposta`, updateGDProposta, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const CreateGDProposta = (createGDProposta: ICreateGDProposta) => {
  return http.post<IGDProposta>(`/GDProposta`, createGDProposta);
};

export const GetPropostaWattwise = (
  id: string | null
): Promise<AxiosResponse<IGDProposta>> => {
  return http.get<IGDProposta>(`/GDProposta/GetDadosToPdf/${id}`);
};

export const GetCalculoProposta = (getCalculoData: IGetCalculoParams) => {
  return http.get<IGetCalculoResponse>(
    `/GDProposta/GetCalculoProposta?TipoConexao=${getCalculoData.tipoConexao}&ValorConta=${getCalculoData.valorConta}&IluminacaoPublica=${getCalculoData.iluminacaoPublica}&ConsumoMensalkWh=${getCalculoData.consumoMensalkWh}&TarifaDistribuidora=${getCalculoData.tarifaDistribuidora}&PisConfins=${getCalculoData.pisConfins}&PercentualDesconto=${getCalculoData.percentualDesconto}`
  );
};
